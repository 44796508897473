.schedule-header {
  display: flex;
  justify-content: space-between;
  background-color: #222222;
  color: white;
  .schedule-title {
    font-size: 25px;
    margin: 20px;
  }
}

.checks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: auto;
  background-color: rgb(18, 32, 48);
}
