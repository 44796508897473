.rigcheck-card {
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #e1e1e1;
  background-color: #245469;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  width: 85%;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    flex-direction: column;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 10px;
    background-color: #122a35;
    box-sizing: border-box;

    h1 {
      margin: 0;
      font-size: 32px;
      color: white;
    }

    .icon-round {
      padding: 5px 10px;
      margin-right: 5px;
      width: fit-content;
      font-size: 14px;
      border-radius: 25px;
      background-color: #0374d8;
      color: white;
      font-weight: bold;
    }

    .icon-square {
      padding: 5px 10px;
      width: fit-content;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }

    span {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;

    .detail-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 100%;
      border: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1;
      box-sizing: content-box;

      .item-count {
        font-size: 28px;
        font-weight: bold;
      }

      .text-description {
        font-size: 20px;
      }
    }
  }
}
