.modern-date-input {
  margin-bottom: 1rem;

  .date-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #fff;
    font-size: 0.9rem;

    .required-mark {
      color: #dc3545;
      margin-left: 4px;
    }
  }

  .date-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .modern-date-control {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 2.5rem;
      }

      &:hover {
        border-color: #80bdff;
      }

      &:disabled {
        background-color: #e9ecef;
        cursor: not-allowed;
      }
    }

    .date-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      color: #6c757d;
    }
  }
}
