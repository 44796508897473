.background-container {
  background-color: rgb(18, 32, 48);
  width: 98%;
  margin: auto;

  .form-generator-container {
    color: white;
    margin: 0px auto 0;
    text-align: left;
    max-width: 800px;
    // border: 1px solid lightgray;
    // border-radius: 20px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 50px;

    .form-title {
      font-size: 38px;
      font-weight: 300;
      color: white;
    }

    .required-text {
      color: white;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    span {
      background-color: rgb(18, 32, 48) !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    .label-required::after {
      content: "*";
      visibility: visible;
      display: block;
      font-size: 18px;
      color: red !important;
      margin: 0%;
    }

    .label-required {
      font-size: 0 !important; /* This hides the text without affecting layout */
      visibility: hidden;
    }

    .form-control {
      padding: 12px !important;
      border: 2px solid #233a6c;
      height: fit-content;
    }
  }

  // .custom-radio {
  //     background-color: black !important;
  // }
  // .custom-control-label {

  // }

  .custom-radio-passfail {
    width: 50%;
    margin-right: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  input[type="radio"][value="fail" i] + .custom-control-label-passfail,
  input[type="radio"][value="pass" i] + .custom-control-label-passfail,
  input[type="radio"][value="yes" i] + .custom-control-label-passfail,
  input[type="radio"][value="broken" i]
    + .custom-control-label-passfail,
  input[type="radio"][value="sealed" i]
    + .custom-control-label-passfail,
  input[type="radio"][value="no" i] + .custom-control-label-passfail {
    padding: 7px;
    width: 90%;
    background-color: #123647;
    border-radius: 5px;
  }

  // #fid_preview_Fail:checked+.custom-control-label, #fid_preview_Fail:hover+.custom-control-label  {
  //     color: white;
  //     background-color: red;
  //     transition: 350ms;
  // }

  // #fid_preview_Pass:hover+.custom-control-label, #fid_preview_Pass:checked+.custom-control-label {
  //     color: white;
  //     background-color: #008000;
  //     transition: 350ms;
  // }

  /* Common styles for fail and broken */
  input[type="radio"][value="fail" i]:checked + label,
  input[type="radio"][value="fail" i] + label:hover,
  input[type="radio"][value="broken" i]:checked + label,
  input[type="radio"][value="broken" i] + label:hover,
  input[type="radio"][value="no" i]:checked + label,
  input[type="radio"][value="no" i] + label:hover {
    color: white;
    background-color: red;
    transition: 350ms;
  }

  .checked {
    color: white;
    background-color: red;
    transition: 350ms;
    position: absolute;
    margin-top: 3px;
    background: red;
    border-radius: 5px;
  }

  input[type="radio"][value="pass" i]:checked + label,
  input[type="radio"][value="pass" i] + label:hover,
  input[type="radio"][value="sealed" i]:checked + label,
  input[type="radio"][value="sealed" i] + label:hover,
  input[type="radio"][value="yes" i]:checked + label,
  input[type="radio"][value="yes" i] + label:hover {
    color: white;
    background-color: #008000;
    transition: 350ms;
  }

  /* Hide ::before and ::after pseudo-elements for passfail labels */
  input[type="radio"][value="fail" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="pass" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="yes" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="fail" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="pass" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="yes" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="broken" i]
    + .custom-control-label-passfail::before,
  input[type="radio"][value="sealed" i]
    + .custom-control-label-passfail::before,
  input[type="radio"][value="no" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="broken" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="sealed" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="no" i] + .custom-control-label-passfail::after {
    display: none !important;
  }

  // hide and display seal components based on sealed or broken radio button
  .SortableItem .new-seal-number,
  .SortableItem .replace {
    display: none;
  }

  .sealed-broken,
  .replace {
    margin-bottom: 0;
  }

  .btn {
    background-color: #233a6c;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    transition: 350ms;

    &:hover {
      background-color: #142442;
      transition: 350ms;
      color: white;
    }
  }
}

.alert.alert-danger.validation-error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 500px;
  width: 90%;
  background-color: rgb(255, 255, 255);                                
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 0;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(246, 245, 245);
    z-index: -1;
  }

  .clearfix {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }     
  }

  i.fas.fa-exclamation-triangle {
    color: #dc3545;
    font-size: 20px;
    margin-right: 10px;
  }

  ul {
    list-style: none;
    padding-left: 10px;
    margin: 0;
    color: #dc3545;
  }

  .btn-danger {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #dc3545;
    border: none;
    
    &:hover {
      background-color: darken(#dc3545, 10%);
    }
  }
}

.pill-checkbox {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
}

.pill-checkbox .custom-control-input {
  display: none; /* Hide default checkbox */
}

.pill-checkbox .pill-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pill-checkbox .pill-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75px;
  height: 30px;
  border-radius: 15px;
  padding: 0.5em;
  margin-right: 10px;
  position: relative;
}

.pill-checkbox .pill-box .checkmark,
.pill-checkbox .pill-box .cross {
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-size: 0.8em;
}

.pill-checkbox .pill-box .checkmark {
  background-color: #f3f3f3;
  color: #afafaf;

  &.active {
    background-color: #4caf50 !important;
    color: white;
  }
}

.pill-checkbox .pill-box .cross {
  background-color: #f3f3f3;
  color: #afafaf;

  &.active {
    background-color: #f44336 !important;
    color: white;
  }
  margin-right: 15px !important;
}

.pill-checkbox .option-text {
  font-size: 1em;
  font-weight: bold;
}

.custom-control.custom-checkbox .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-label::after {
  content: none;
  background: none;
  border: none;
}

.custom-control.custom-checkbox {
  position: relative;
  margin: 0.5em 0;
}

.custom-control.custom-checkbox .custom-control-input {
  display: none;
}

.custom-control.custom-checkbox .custom-control-label {
  position: relative;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  padding-left: 90px;
  display: block;
}

.custom-control.custom-checkbox .custom-control-label::before {
  content: "✖";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 30px;
  border-radius: 15px;
  background-color: #f44336;
  color: white;
  font-size: 1.2em;
}

.custom-control.custom-checkbox
  .custom-control-input:checked
  + .custom-control-label::before {
  content: "✔";
  background-color: #4caf50 !important;
  color: white;
}
